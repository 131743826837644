.root {
	position: relative;
	& :global(.hashidaysConference) {
		--conferenceEyebrowGradient: to right, #2E71E5;
		--conferenceGradient: 60deg, #A067DA 2.3%, #2E71E5 63.52%, #6BCCE3 124.74%;

		& :global(.hds-button:not(:disabled)) {
			background: linear-gradient(var(--conferenceGradient));
			border: none;
		}

		& :global(.hashidaysGlow) {
			--gradientColors:rgba(160, 103, 218, 0.9) 5%, rgba(2, 168, 239, 0.66) 28.46%, rgba(255, 255, 255, 0.00) 51.64%;

			pointer-events: none;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 1000px;
			background: linear-gradient(236deg, var(--gradientColors));
		
			@media (--medium-up) {
				background: radial-gradient(ellipse at 95% 15%, var(--gradientColors));
			}

			@media (--large) {
				background: radial-gradient(ellipse at 90% 15%, var(--gradientColors));
			}
		}

		& :global(.registration-cta) :global(.hds-button:not(:disabled)) {
			background: unset;
			color: var(--hdsplus-color-secondary-white-button-color);
			background-color: var(--hdsplus-color-secondary-white-button-background-color);
			border-color: var(--hdsplus-color-secondary-white-button-border-color, var(--token-color-border-primary));
			box-shadow: var(--token-elevation-low-box-shadow);
		}
	}
	
	& :global(.hashiconfConference) {
		--conferenceGradient: to right, var(--token-color-terraform-foreground), var(--token-color-vagrant-foreground);
		--conferenceGradientFull: #ED3B7C, #A067DA, #3A80FA;

		& :global(.hds-button:not(:disabled)) {
			background: linear-gradient(var(--conferenceGradient));
			color: #efeff1;
			border: none;
		}

		& :global(.hds-tabs__tab.hds-tabs__tab--is-selected .hds-tabs__tab-button) { 
			color: var(--token-color-terraform-foreground);
		}

		& :global(.hds-tabs__tab:hover .hds-tabs__tab-button) { 
			color: var(--token-color-terraform-foreground);
		}

		& :global(.hds-tabs__tab-indicator) {
			background: var(--token-color-terraform-foreground);
			
			&:hover {
				background: var(--token-color-terraform-foreground);
			}
		}

		& :global(.hds-tabs__tab.hds-tabs__tab--is-selected:hover ~ .hds-tabs__tab-indicator) {
			background: var(--token-color-terraform-foreground);
		}
	}
}