@custom-media --dev-dot-viewport (min-width: 924px);

.column {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 190px;
	padding: 0;
	margin: 0 var(--hdsplus-spacing-07);
	border-radius: var(--hdsplus-spacing-03);

	@media (--large) {
		&:not(.hasSide) {
			margin: 0;
		}
	}

	@media (--dev-dot-viewport) {
		&.hasSide {
			margin: 0;
		}
	}
}
