@custom-media --dev-dot-viewport (min-width: 924px);

.root {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* stylelint-disable-next-line unit-no-unknown */
	min-height: calc(100dvh - var(--hdsplus-spacing-12));

	@media (--large) {
		&:not(.hasSide) {
			justify-content: flex-start;
			height: 100%;
			min-height: fit-content;
			padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-04);
			gap: var(--hdsplus-spacing-04);
		}
	}

	@media (--dev-dot-viewport) {
		&.hasSide {
			justify-content: flex-start;
			height: 100%;
			min-height: fit-content;
			padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-04);
			gap: var(--hdsplus-spacing-04);
		}
	}
}
