.root {
	composes: g-focus-border-and-box-shadow-light from global;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 0 3px var(--token-color-focus-action-external),
		0 1px 1px rgba(101, 106, 118, 0.05), 0 2px 2px rgba(101, 106, 118, 0.05);
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	padding: 8px;
	position: absolute;
	left: -10000px; /* keeps button out of view when not focused */
	top: 3px;
	z-index: 666666668; /* higher than the z-index of the nav */

	&:focus {
		left: 3px;
	}

	&:hover {
		text-decoration: underline;
	}
}