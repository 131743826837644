.linkList {
	display: flex;
	list-style: none;
	flex-direction: column;
	gap: var(--hdsplus-spacing-03);
	margin: 0;
	padding: 0;
}

.link {
	color: var(--token-color-foreground-primary);
	display: flex;
	gap: var(--hdsplus-spacing-04);
	padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-04);
	min-width: 190px;
	align-items: center;

	&:hover {
		background-color: var(--token-color-surface-interactive-hover);
		border-radius: var(--hdsplus-spacing-01);
	}

	&:has(.iconBackground) {
		align-items: flex-start;
	}

	@media (--large) {
		max-width: 416px;
	}

	&:focus {
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-style: auto;
	}
}

.description {
	font-size: var(--hdsplus-typography-body-200-font-size);
	font-weight: var(--token-typography-font-weight-regular);
	margin-top: var(--hdsplus-spacing-02);
}

.iconBackground {
	background: var(--iconGradient);
	height: var(--hdsplus-spacing-08);
	width: var(--hdsplus-spacing-08);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	flex: none;
	margin-top: 3px;
}

.boldTitle {
	font-weight: var(--font-weight-semi-bold);
}
