@custom-media --dev-dot-viewport (min-width: 924px);

.productPanel {
	height: 100%;

	@media (--large) {
		&.noSide {
			display: grid;
			grid-template-rows: 1fr;
			grid-template-columns: max-content max-content;
			padding: 0;
		}
	}

	@media (--dev-dot-viewport) {
		&.hasSide {
			display: grid;
			grid-template-rows: 1fr;
			grid-template-columns: max-content max-content;
			padding: 0;
		}
	}
}

.productCategories {
	list-style: none;
	padding: 0;
	display: grid;
	margin: var(--hdsplus-spacing-07) 0 0;

	@media (--large) {
		@nest .noSide & {
			margin: 0;
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			margin: 0;
		}
	}
}

.productCategory {
	display: flex;
	flex-direction: column;
	min-width: 160px;

	&:first-child {
		border-bottom: 1px solid var(--token-color-border-primary);
		margin-bottom: var(--hdsplus-spacing-05);
		padding-bottom: var(--hdsplus-spacing-05);
	}
}

.eyebrow {
	padding-bottom: var(--hdsplus-spacing-04);
	color: var(--token-color-foreground-strong);
	font-weight: var(--font-weight-semi-bold);

	@media (--large) {
		@nest .noSide & {
			padding-bottom: 0;
			padding-left: var(--hdsplus-spacing-06);
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			padding-bottom: 0;
			padding-left: var(--hdsplus-spacing-06);
		}
	}
}

.productList {
	display: grid;
	grid-template-columns: 1fr;
	list-style: none;
	margin: 0;
	padding: 0;

	@media (--large) {
		@nest .noSide & {
			padding: var(--hdsplus-spacing-06) var(--hdsplus-spacing-05)
				var(--hdsplus-spacing-04);
			grid-auto-flow: row;
			grid-template-columns: repeat(2, 300px);
			grid-template-rows: repeat(2, max-content);
			gap: 0;
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			padding: var(--hdsplus-spacing-06) var(--hdsplus-spacing-05)
				var(--hdsplus-spacing-04);
			grid-auto-flow: row;
			grid-template-columns: repeat(2, 300px);
			grid-template-rows: repeat(2, max-content);
			gap: 0;
		}
	}

	& li:last-child {
		& .productWrapper {
			margin-bottom: 0;
		}
	}
}

/* NavProduct styles */

.productWrapper {
	display: flex;
	margin-bottom: var(--hdsplus-spacing-05);
	gap: var(--hdsplus-spacing-05);

	@media (--large) {
		@nest .noSide & {
			padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-05);
			margin-bottom: 0;

			&:hover {
				background-color: var(--token-color-surface-interactive-hover);
				border-radius: var(--hdsplus-spacing-01);
			}
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-05);
			margin-bottom: 0;

			&:hover {
				background-color: var(--token-color-surface-interactive-hover);
				border-radius: var(--hdsplus-spacing-01);
			}
		}
	}
}

.productLogo {
	margin-top: 3px;

	& svg {
		height: 42px;
		width: 42px;
	}

	@nest .noSide & {
		& svg {
			@media (--large) {
				height: var(--hdsplus-spacing-08);
				width: var(--hdsplus-spacing-08);
			}
		}
	}

	@nest .hasSide & {
		& svg {
			@media (--dev-dot-viewport) {
				height: var(--hdsplus-spacing-08);
				width: var(--hdsplus-spacing-08);
			}
		}
	}
}

.productTextContent {
	display: flex;
	flex-direction: column;
	color: var(--token-color-foreground-strong);
}

.productDesc {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.0125em;
	color: var(--token-color-foreground-faint);
}

.focusIndicator {
	&:focus {
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-style: auto;
	}
}

.mainPanel {
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@media (--large) {
		@nest .noSide & {
			padding: var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-09)
				var(--hdsplus-spacing-04);
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			padding: var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-09)
				var(--hdsplus-spacing-04);
		}
	}
}

.sidePanel {
	height: 100%;
	background: var(--token-color-surface-faint);
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-05);
	margin: 0;
	padding: var(--hdsplus-spacing-07) var(--hdsplus-spacing-04)
		var(--hdsplus-spacing-09) var(--hdsplus-spacing-07);
}

.sidePanelItems {
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--hdsplus-spacing-07);

	@media (--dev-dot-viewport) {
		gap: var(--hdsplus-spacing-05);
	}
}

/* Lifted from .productWrapper */
.sidePanelItem {
	display: flex;
	gap: var(--hdsplus-spacing-05);

	@media (--dev-dot-viewport) {
		padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-05);

		&:hover {
			background-color: var(--token-color-surface-interactive-hover);
			border-radius: var(--hdsplus-spacing-01);
		}
	}
}

/* Lifted from:
packages/react-components/src/components/nav-panel/components/link-list/style.module.css  */
.iconBackground {
	background: var(--token-color-foreground-high-contrast);
	height: var(--hdsplus-spacing-08);
	width: var(--hdsplus-spacing-08);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	flex: none;
	margin-top: 3px;
	border: 1px solid var(--token-color-border-primary);
}

/* Lifted from .productTextContent */
.sidePanelItemContent {
	display: flex;
	flex-direction: column;
	color: var(--token-color-foreground-strong);
}

.sidePanelItemDesc {
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: var(--token-color-foreground-primary);
}

/* Branding exception classnames */

/* Allows switching to a dark-mode-specific color for Terraform */
.terraform {
	& use {
		fill: var(--token-color-terraform-brand);

		@nest [data-theme='dark'] & {
			fill: var(--token-color-terraform-foreground);
		}
	}

	& path {
		fill: inherit;
	}
}

/* Styles for HCP Vault mimic the spec for `product-square` icons,
	while allowing the symbol inside the icon border to change color
	based on the site's theme
*/
.hcpVaultSecrets,
.hcpVaultRadar {
	padding: var(--hdsplus-spacing-01);
	border: 2px solid var(--token-color-vault-brand);
	border-radius: 3px;

	& use {
		fill: var(--token-color-foreground-strong);
	}

	& path {
		fill: inherit;
	}
}
