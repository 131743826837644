:global(.hdsplus-dark-mode), :global([data-theme="dark"]) {
	& .hds-link-standalone--color-secondary {
		&:hover,
		&.mock-hover {
			.hds-link-standalone__text {
				text-decoration-color: var(--token-color-foreground-strong);
			}
		}
	
		&:active,
		&.mock-active {
			.hds-link-standalone__text {
				text-decoration-color: var(--token-color-foreground-strong);
			}
		}
	}
}

.mds-link-standalone--color-secondary-inverted {
	color: var(--token-color-palette-neutral-50);

	&:hover,
	&.mock-hover {
		color: var(--token-color-palette-neutral-300);

		.hds-link-standalone__text {
			text-decoration-color: var(--token-color-palette-neutral-300);
		}
	}

	&:active,
	&.mock-active {
		color: var(--token-color-palette-neutral-300);

		.hds-link-standalone__text {
			text-decoration-color: var(--token-color-palette-neutral-300);
		}

		&::before {
			background-color: var(--token-color-palette-neutral-600);
		}
	}
}
