.columns {
	display: flex;
	gap: var(--hdsplus-spacing-03);
	flex-direction: column;
	margin-top: var(--hdsplus-spacing-07);

	@media (--large) {
		flex-direction: row;
		margin-top: 0;
	}
}
