.image {
	border-radius: var(--hdsplus-spacing-01);
	overflow: hidden;
	aspect-ratio: 1/1;
	margin-bottom: var(--hdsplus-spacing-05);

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

.name {
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-03);
}

.social {
	margin-top: var(--hdsplus-spacing-05);
}

.icon {
	height: 100%;
	display: flex;
}

.link {
	margin-top: var(--hdsplus-spacing-05);
}