@custom-media --dev-dot-viewport (min-width: 924px);

.promo {
	display: flex;
	border-radius: var(--hdsplus-spacing-01);
	border: 1px solid var(--token-color-border-faint);
	background-color: var(--token-color-surface-faint);
	padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-08)
		var(--hdsplus-spacing-06) var(--hdsplus-spacing-08);
	flex-direction: row;
	gap: var(--hdsplus-spacing-04);
	margin: var(--hdsplus-spacing-07);
	margin-bottom: var(--hdsplus-spacing-09);

	@media (--large) {
		&.noSide {
			margin: 0;
			width: min-content;
			min-width: 100%;
		}
	}

	@media (--dev-dot-viewport) {
		&.hasSide {
			margin: 0;
			width: min-content;
			min-width: 100%;
		}
	}
}

.rightContent {
	display: flex;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-03);
	width: 100%;
	justify-content: space-between;
}

.title {
	font-weight: var(--font-weight-semi-bold);
}

.description {
	font-size: var(--hdsplus-typography-body-200-font-size);
	font-weight: var(--token-typography-font-weight-regular);
	margin-top: var(--hdsplus-spacing-01);
}

.icon {
	display: none;

	@media (--large) {
		@nest .noSide & {
			display: block;
			margin-top: 3px;
		}
	}

	@media (--dev-dot-viewport) {
		@nest .hasSide & {
			display: block;
			margin-top: 3px;
		}
	}
}

.link {
	color: var(--token-color-foreground-strong);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--hdsplus-spacing-02);

	&:hover {
		text-decoration: underline;
	}

	&:focus {
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-style: auto;
	}
}

.hashidays {
	background: linear-gradient(
		90deg,
		#7b54b6 0%,
		#4c69d8 74.77%,
		#60d0dc 115.93%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	max-width: fit-content;

	& svg {
		color: #7956b9;
	}
}

.hashiconf {
	background: linear-gradient(
		to right,
		var(--token-color-terraform-foreground),
		var(--token-color-vagrant-foreground)
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	max-width: fit-content;

	& svg {
		color: var(--token-color-terraform-foreground);
	}
}
