.application {
	composes: g-grid-container from global;
	padding-top: 80px;
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	gap: var(--wpl-spacing-05);

	& .backToListing {
		padding-bottom: 2rem;
	}


	& .mdsForm {
		display: flex;
		flex-direction: column;
		gap: var(--wpl-spacing-05);
		max-width: 700px;
	}

	& button[type='submit'] {
		width: fit-content;
	}

	/* 
	TODO: These padding resets should not be necessary, typography padding is overring the actual padding
	however, it to be fixed in this form
	see here: https://hashicorp-www-next-git-www-i18n-contact-sales-page-hashicorp.vercel.app/contact-sales
	*/

	& input[type='text'] {
		padding-left: var(--wpl-spacing-02);
	}

	& input[type='month'] {
		padding-left: var(--wpl-spacing-02);
		padding-right: var(--wpl-spacing-02);
	}

	& select {
		width: 100%;
		padding-left: var(--wpl-spacing-02);
	}

	& iframe {
		width: 100%;
		padding-left: var(--wpl-spacing-02);

	}
}

.heading {
	composes: g-type-display-2 from global;
}

.demographicQuestions {
	display: flex;
	flex-direction: column;
	gap: var(--wpl-spacing-05);
	margin-bottom: var(--wpl-spacing-05);
}

.demographicQuestionsHeader {
	composes: g-type-display-4 from global;
	margin-bottom: 0;
}

.dateRangeInputs {
	& label {
		margin-bottom: var(--wpl-spacing-01);
	}
}
